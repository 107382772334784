<template>
  <div class="banner" id="home" v-if="$route.name==='Home'">
    <slider></slider>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Slider from "@/components/core-ui/slider/slider.vue";

export default defineComponent({
  name: "banner",
  components: { Slider }
});
</script>

<style scoped>
.banner {
  position: relative;
  width: 100%;
}



</style>