<template>
  <div class="contact" id="contact">
    <div class="contact__inner">
      <div class="left__column">
        <img src="../../../assets/images/con1.jpg">
      </div>
      <div class="right__column">
        <div class="right__column__inner">
          <p class="title">Get in touch</p>
          <h1>ALPH4 MEP LIMITED</h1>
          <p class="subtitle _subtitle">
            <b>Corporate Head Office:</b> Plot D Block 7 Industrial Crescent Ilupeju, Lagos State<br>
            <b>Victoria Island Office:</b> 1st Floor, 54a Adeola Odeku Street, Victoria Island, Lagos, Nigeria<br>
            <b>E:</b> info@alph4mep.ng, <b>T:</b> + 234 906 245 7065
          </p>
          <p class="say-hello">Want to say hello? Want to know more about us? Drop us an
            email and we will get back to you as soon as we can.
          </p>
          <form action="">
            <div class="form__control">
              <input type="text" placeholder="Name*">
            </div>
            <div class="form__control">
              <input type="text" placeholder="Email Address*">
            </div>
            <div class="form__control">
              <input type="text" placeholder="Company name*">
            </div>
            <div class="form__control">
              <input type="text" placeholder="Phone number*">
            </div>
            <div class="form__control form__control__textarea">
              <textarea name="" id="" cols="30" rows="5"></textarea>
            </div>

            <button class="send">Send my message</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "contact",
})
</script>

<style scoped>
.contact {
  background: var(--color-white);
  height: 80rem;
  margin-bottom: .5rem;
}
.contact__inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  /*max-width: 1440px;*/
  margin: 0 auto;
}
.left__column {
  width: 100%;
}
.left__column img {
  width: 100%;
  height: 80rem;
  object-fit: cover;
}
.right__column {
  padding: 0 5rem;
  height: 80rem;
  background-color: #323A45;
}
.right__column__inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
}
.title {
  margin-bottom: 3rem;
  font-size: var(--fs-32);
  color: var(--color-white);
  font-weight: var(--font-weight-light);
}
._subtitle {
  margin-bottom: 10px !important;
  font-size: 13px;
  text-align: justify;
  color: var(--color-white);
  line-height: 150%;
  font-weight: var(--font-weight-light);
}
.say-hello {
  font-size: 12px;
  font-style: italic;
  margin-bottom: 30px;
  text-align: justify;
  color: var(--color-white);
  font-weight: var(--font-weight-light);
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}
.form__control {
  width: 100%;
  background-color: #2E353F;
}
.form__control input {
  padding: 1rem;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: 1px solid #2E353F;
  color: var(--color-white);
}
.form__control__textarea {
  grid-column: 1/-1;
  background-color: #2E353F;
}
textarea {
  width: 100%;
  background-color: transparent;
  outline: none;
  padding: 1rem;
  color: var(--color-white);
  border: none;
}
.send {
  padding: 1rem 2rem;
  background-color: var(--primary-color);
  color: var(--color-white);
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
  width: min-content;
  white-space: nowrap;
  border-radius: 4rem;
}
.right__column__inner h1 {
  color: var(--color-white);
  margin-bottom: 1rem;
}

@media screen and (max-width: 950px) {
  .contact__inner {
    grid-template-columns: 1fr;
  }
  .left__column {
    /*height: 30rem;*/
    /*margin-bottom: .5rem;*/
  }
  .left__column img {
    /*height: 30rem;*/
  }
  .right__column {
    padding: 2rem 3rem;
    height: auto;
    background-color: #323A45;
  }
  .title {
    margin-bottom: 1rem;
  }
}

</style>