<template>
  <div class="slider">
    <Carousel :autoplay="10000" :wrap-around="true">
      <Slide v-for="slide in slides" :key="slide">
        <div class="carousel__item">
          <div class="overlay"></div>
          <img :src="require(`../../../assets/images/${slide.imgUrl}`)">
          <div class="slider__article">
            <p class="slider__title">MECHANICAL, ELECTRICAL, PLUMBING CONTRACTING</p>
            <p class="slider__subtitle">Delivering innovative engineering solutions for complex projects</p>
          </div>
        </div>
      </Slide>

      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default defineComponent({
  name: "slider",
  components: {
    Carousel,
    Slide,
  },
  setup() {
    const slides = reactive([
      {
        imgUrl: "slider-image-2.jpg",
      },
      {
        imgUrl: "slider-image-1.jpg",
      },
      {
        imgUrl: "slider-image-3.jpg",
      },
      {
        imgUrl: "slider-image-4.jpg",
      },
    ])

    return {
      slides
    }
  }

});
</script>

<style scoped>
.carousel__item {
  height: 70rem;
  width: 100%;
  position: relative;
}
.carousel__item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  height: 70rem;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70rem;
  background-color: var(--primary-color);
  opacity: .6;
  z-index: 2;
}
.slider__article {
  z-index: 10;
  position: relative;
  width: 100%;
  height: 70rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.slider__title {
  font-size: var(--fs-20);
  color: var(--color-white);
  max-width: 100rem;
  text-align: center;
  margin: 0 auto 1rem auto;
}
.slider__subtitle {
  margin: 0 auto;
  font-size: var(--fs-60);
  max-width: 100rem;
  color: var(--color-white);
  font-weight: var(--font-wieght-semibold);
}

@media screen and (max-width: 750px) {
  .slider__title {
    font-size: var(--fs-16);
    width: 90%;
  }
  .slider__subtitle {
    font-size: var(--fs-32);
    width: 95%;
  }
}
@media screen and (max-width: 550px) {
  .carousel__item {
    height: 30rem;
  }
  .carousel__item img {
    height: 30rem;
  }
  .overlay {
    height: 30rem;
  }
  .slider__article {
    height: 30rem;
  }
}
</style>