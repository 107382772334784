<template>
  <div class="scrollspy">
    <div class="scrollspy-nav">
      <p>Home</p>
      <a href="#home" class="diamonds"></a>
    </div>
    <div class="scrollspy-nav">
      <p>About</p>
      <a href="#about-us" class="diamonds"></a>
    </div>
    <div class="scrollspy-nav">
      <p>Services</p>
      <a href="#services" class="diamonds"></a>
    </div>
    <div class="scrollspy-nav">
      <p>Contact</p>
      <a href="#contact" class="diamonds"></a>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "scrollspy",
});
</script>

<style scoped>
.scrollspy {
  position: fixed;
  right: 1rem;
  top: 30rem;
  z-index: 999;
}
.scrollspy-nav {
  display: flex;
  align-items: center;
}
.scrollspy-nav:hover .diamonds {
  transform: rotate(45deg);
}
.scrollspy-nav:hover p {
  transform: scale(1);
}
.scrollspy-nav p {
  width: 7rem;
  color: #000;
  padding: .5rem;
  border-radius: 1rem;
  background-color: #b1adad;
  transition: transform .2s ease;
  transform: scale(0);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-12);
  font-weight: var(--font-wieght-semibold);
}
.diamonds {
  width: 1rem;
  height: 1rem;
  background-color: #b1adad;
  transition: transform .3s ease;
  margin-left: .3rem;
  cursor: pointer;
}

</style>