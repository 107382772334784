<template>
  <div class="about" id="about-us">
    <div class="about__inner">
      <div class="left__column">
        <img src="../../../assets/images/abt.jpg">
      </div>
      <div class="right__column">
        <div class="right__column__inner">
          <h3 class="title">About Us</h3>
          <p class="_about__1">Alph4 MEP was founded in 2018 as a full-service contracting firm in design, installation,
            and service of Mechanical, Electrical, HVAC and Plumbing works.
          </p>
          <p>We are a team of experienced Mechanical and Electrical Engineers with extensive knowledge in engineering project design
            and construction works. Our projects cut across various sectors and at all stages of a
            building's life cycle. Over the years, we have been involved in projects owned by Government,
            Multinational Corporations, Industrial Groups, as well as High Net-worth Individuals,
            providing high quality, value-driven, build and managed solutions.
          </p>
          <P>At Alph 4MEP, our goal is to deliver high standard systems to our clients on time and within budget.</P>

          <div class="download">
            <a href="https://files.fm/u/x5k74rg4u#/view/aempqjujc" target="_blank" class="download-portfolio">Corporate Profile</a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "about",
})
</script>

<style scoped>
.about {
  background: var(--color-white);
  height: 80rem;
}
.about__inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  /*max-width: 1440px;*/
  margin: 0 auto;
}
.left__column {
  width: 100%;
}
.left__column img {
  width: 100%;
  height: 80rem;
  object-fit: cover;
}
.right__column {
  padding: 0 5rem;
  height: 80rem;
}
.right__column__inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  margin-bottom: 3rem;
  font-size: var(--fs-32);
  color: var(--primary-color);
  font-weight: normal;
}
._about__1 {
  margin-bottom: 2rem !important;
  font-size: var(--fs-20) !important;
  color: #2d2d2d !important;
  line-height: 150% !important;
}
.right__column p {
  color: #424141;
  font-size: var(--fs-14);
  margin-bottom: 1rem;
  line-height: 200%;
  max-width: 800px;
}
.download {
   margin-top: 12rem;
 }
.right__column a {
  background-color: var(--primary-color);
  color: var(--color-white);
  padding: 1rem 2rem;
  text-decoration: none;
  font-size: var(--fs-14);
  cursor: pointer;
  border-radius: 4rem;
}
@media screen and (max-width: 900px) {
  .about {
    height: auto;
  }
  .about__inner {
    grid-template-columns: 1fr;
    height: 100%;
  }
  .left__column img {
    /*display: none;*/
  }
  .right__column {
    padding: 3rem;
    height: auto;
  }
  .right__column__inner {
    height: auto;
  }
  .download {
    margin-top: 4rem;
  }
}
</style>