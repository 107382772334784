<template>
  <div class="services" id="services">
    <div class="services__inner">
      <div class="left__column">
        <div class="left__column__inner">
          <h3 class="title">Services</h3>
          <p class="services__subtitle">
            Alph4 MEP services include general contracting on all types of Mechanical,
            Electrical, Plumbing (MEP), and Fire Protection Systems installation and
            design works for a wide range of industrial, commercial, healthcare,
            multi-residential buildings, and institutional clients.
          </p>

          <div class="services__slide">
            <div class="services__column">
              <svg
                  enable-background="new 0 0 24 24"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                  class="services-icon"
              >
                <g>
                  <rect
                      fill="none"
                      height="24"
                      width="24"
                  />
                </g>
                <g>
                  <g>
                    <rect
                        height="8.48"
                        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.8717 17.6255)"
                        width="3" x="16.34" y="12.87"
                    />
                    <path d="M17.5,10c1.93,0,3.5-1.57,3.5-3.5c0-0.58-0.16-1.12-0.41-1.6l-2.7,2.7L16.4,6.11l2.7-2.7C18.62,3.16,18.08,3,17.5,3 C15.57,3,14,4.57,14,6.5c0,0.41,0.08,0.8,0.21,1.16l-1.85,1.85l-1.78-1.78l0.71-0.71L9.88,5.61L12,3.49 c-1.17-1.17-3.07-1.17-4.24,0L4.22,7.03l1.41,1.41H2.81L2.1,9.15l3.54,3.54l0.71-0.71V9.15l1.41,1.41l0.71-0.71l1.78,1.78 l-7.41,7.41l2.12,2.12L16.34,9.79C16.7,9.92,17.09,10,17.5,10z"
                    />
                  </g>
                </g>
              </svg>
              <h3 class="service__title">Mechanical Engineering</h3>
              <ul class="services-list">
                <li>Heating, Ventilation, Air Conditioning and Refrigeration (HVACR)</li>
                <li>Domestic water services, Drainage, and Cooling</li>
                <li>Displacement and Demand Controlled Ventilation</li>
                <li>Piping and Instrumentation Diagrams (P&ID)</li>
                <li>BMS and control systems</li>
                <li>Mechanical Process Distribution</li>
                <li>Central Utility Plants</li>
                <li>Efficiency Improvements</li>
                <li>Equipment Replacements</li>
                <li>Humidification & Temperature Control</li>
                <li>Medical System Design</li>
              </ul>
            </div>

            <div class="services__column">
              <svg
                  enable-background="new 0 0 24 24"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                  class="services-icon"
              >
                <g>
                  <rect
                      fill="none"
                      height="24"
                      width="24"
                  />
                </g>
                <g>
                  <g>
                    <path
                        d="M21,14c0-0.55-0.45-1-1-1h-2v2h2C20.55,15,21,14.55,21,14z"
                    />
                    <path d="M20,17h-2v2h2c0.55,0,1-0.45,1-1C21,17.45,20.55,17,20,17z"
                    />
                    <path d="M12,14h-2v4h2c0,1.1,0.9,2,2,2h3v-8h-3C12.9,12,12,12.9,12,14z"
                    />
                    <path d="M5,13c0-1.1,0.9-2,2-2h1.5c1.93,0,3.5-1.57,3.5-3.5S10.43,4,8.5,4H5C4.45,4,4,4.45,4,5c0,0.55,0.45,1,1,1h3.5 C9.33,6,10,6.67,10,7.5S9.33,9,8.5,9H7c-2.21,0-4,1.79-4,4c0,2.21,1.79,4,4,4h2v-2H7C5.9,15,5,14.1,5,13z"
                    />
                  </g>
                </g>
              </svg>
              <h3 class="service__title">Electrical Engineering</h3>
              <ul class="services-list">
                <li>Power distribution</li>
                <li>Lighting systems</li>
                <li>Fire alarm and detection</li>
                <li>Data/communications systems</li>
                <li>Standby power generation/UPS</li>
                <li>Lightning protection</li>
                <li>Emergency Power Systems</li>
                <li>Emergency Lighting Systems</li>
                <li>Interior / Exterior Lighting</li>
                <li>Low Voltage and Time Clock System</li>
              </ul>
            </div>

            <div class="services__column">
              <svg
                  enable-background="new 0 0 24 24"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                  class="services-icon"
              >
                <g>
                  <rect
                      fill="none"
                      height="24"
                      width="24"
                  />
                </g>
                <g>
                  <g>
                    <path d="M19.28,4.93l-2.12-2.12c-0.78-0.78-2.05-0.78-2.83,0L11.5,5.64l2.12,2.12l2.12-2.12l3.54,3.54 C20.45,8,20.45,6.1,19.28,4.93z"
                    />
                    <path d="M5.49,13.77c0.59,0.59,1.54,0.59,2.12,0l2.47-2.47L7.96,9.17l-2.47,2.47C4.9,12.23,4.9,13.18,5.49,13.77L5.49,13.77z"/>
                    <path d="M15.04,7.76l-0.71,0.71l-0.71,0.71l-3.18-3.18C9.85,5.4,8.9,5.4,8.32,5.99c-0.59,0.59-0.59,1.54,0,2.12l3.18,3.18 L10.79,12l-6.36,6.36c-0.78,0.78-0.78,2.05,0,2.83c0.78,0.78,2.05,0.78,2.83,0L16.45,12c0.39,0.39,1.02,0.39,1.41,0 c0.39-0.39,0.39-1.02,0-1.41L15.04,7.76z"/>
                  </g>
                </g>
              </svg>

              <h3 class="service__title">Plumbing Engineering</h3>

              <ul class="services-list">
                <li>Power distribution</li>
                <li>Lighting systems</li>
                <li>Fire alarm and detection</li>
                <li>Data/communications systems</li>
                <li>Standby power generation/UPS</li>
                <li>Lightning protection</li>
                <li>Emergency Power Systems</li>
                <li>Emergency Lighting Systems</li>
                <li>Interior / Exterior Lighting</li>
                <li>Low Voltage and Time Clock System</li>
              </ul>

            </div>

            <div class="services__column">

              <svg
                  enable-background="new 0 0 24 24"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                  class="services-icon"
              >
                <g>
                  <rect
                      fill="none"
                      height="24"
                      width="24"
                  />
                  <path d="M7,19h10v1c0,1.1-0.9,2-2,2H9c-1.1,0-2-0.9-2-2V19z M7,18h10v-5H7V18z M17,3v6l-3.15-0.66c-0.01,0-0.01,0.01-0.02,0.02 c1.55,0.62,2.72,1.98,3.07,3.64H7.1c0.34-1.66,1.52-3.02,3.07-3.64c-0.33-0.26-0.6-0.58-0.8-0.95L5,6.5v-1l4.37-0.91 C9.87,3.65,10.86,3,12,3c0.7,0,1.34,0.25,1.85,0.66L17,3z M13,6c-0.03-0.59-0.45-1-1-1s-1,0.45-1,1s0.45,1,1,1S13,6.55,13,6z"/>
                </g>
              </svg>

              <h3 class="service__title">Fire Protection Engineering</h3>

              <ul class="services-list">
                <li>Power distribution</li>
                <li>Lighting systems</li>
                <li>Fire alarm and detection</li>
                <li>Data/communications systems</li>
                <li>Standby power generation/UPS</li>
                <li>Lightning protection</li>
                <li>Emergency Power Systems</li>
                <li>Emergency Lighting Systems</li>
                <li>Interior / Exterior Lighting</li>
                <li>Low Voltage and Time Clock System</li>
              </ul>

            </div>

          </div>

        </div>
      </div>
      <div class="right__column">
        <div class="right__column__inner">
          <Carousel :autoplay="10000" :wrap-around="true">
            <Slide v-for="slide in images" :key="slide">
              <div class="services__slider">
                <img :src="require(`../../../assets/images/${slide}`)">
              </div>
            </Slide>

            <template #addons>
              <Pagination />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, reactive} from "vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default defineComponent({
  name: "services",
  components: {
    Carousel,
    Slide,
  },
  setup() {
    const images = reactive([
        "ser1.jpg",
        "ser2.jpg",
        "ser3.jpg"
    ])

    return {
      images
    }
  }
})
</script>

<style scoped>
.services {
  height: 80rem;
}
.services__inner {
  display: flex;
  height: 100%;
  /*max-width: 1440px;*/
  margin: 0 auto;
}
.left__column {
  padding: 0 5rem;
  height: 80rem;
  background-color: var(--color-white);
  width: 50%;
}
.left__column__inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;
  /*margin-left: auto;*/
}
.services__slide {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2rem;
  max-width: 80rem;
  overflow-x: auto;
  padding: 2rem;
  width: 100%;
}
.title {
  margin-bottom: 3rem;
  font-size: var(--fs-32);
  font-weight: normal;
  color: var(--primary-color);
}
.left__column p {
  color: #2E353F;
  font-size: var(--fs-14);
  margin-bottom: 1rem;
  line-height: 200%;
}
.services__subtitle {
  margin-bottom: 2rem !important;
  font-size: var(--fs-18) !important;
  color: #2d2d2d !important;
  line-height: 180% !important;
}
.services-icon {
  width: 60px;
  height: 60px;
  fill: var(--primary-color);
  margin-bottom: 10px;
}
.service__title {
  font-size: 16px !important;
  text-transform: uppercase !important;
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-weight: bold !important;
}
.services-list {
  /*padding: 5px;*/
  max-height: 20rem;
  overflow-y: auto;
}
.services-list li {
  margin-bottom: 5px;
  font-size: 1.3rem;
  line-height: 2.34rem;
  color: #2E353F;
  font-weight: 300;
}
.services-list::-webkit-scrollbar {
  width: 5px;
}
.services__slide::-webkit-scrollbar {
  height: 5px;
}
.services-list::-webkit-scrollbar-track,
.services__slide::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.services-list::-webkit-scrollbar-thumb,
.services__slide::-webkit-scrollbar-thumb {
  background-color: #2E353F;
  outline: 1px solid slategrey;
}
.services__column {
  width: 30rem;
}
.right__column {
  width: 50%;
}
.right__column__inner {
  height: 100%;
}
.services__slider {
  width: 100%;
  height: 100%;
}
.services__slider img {
  width: 100%;
  height: 80rem;
  object-fit: cover;
}

@media screen and (max-width: 900px) {
  .services {
    height: auto;
  }
  .services__inner {
    display: flex;
    flex-direction: column-reverse;
  }
  .right__column {
    height: auto;
    width: 100%;
    margin-bottom: .5rem;
  }
  .left__column {
    width: 100%;
  }
  .services__slider {
    /*height: 40rem;*/
  }
  .services__slide {
    max-width: 100vw;
  }
  .services__column {
    width: 50vw;
  }
  .left__column {
    padding: 2rem 3rem;
    height: auto;
    /*background-color: #323A45;*/
  }
  .title {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .services__column {
    width: 100vw;
  }
}


</style>