
import {defineComponent, reactive} from "vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default defineComponent({
  name: "services",
  components: {
    Carousel,
    Slide,
  },
  setup() {
    const images = reactive([
        "ser1.jpg",
        "ser2.jpg",
        "ser3.jpg"
    ])

    return {
      images
    }
  }
})
