<template>
  <header>
    <div class="header__inner">
      <div class="logo">
        <img src="../../../assets/images/logo-1.png" alt="logo">
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "web-view-nav",
});
</script>

<style scoped>
header {
  width: 100%;
  position: absolute;
  background-color: transparent;
  padding: 1rem 0;
  top: 0;
  z-index: 20;
}
.header__inner {
  width: 95%;
  height: 3rem;
  display: grid;
  grid-template-columns: 1fr 80% 1fr;
  align-items: center;
  margin: 0 auto;
}
.logo {
  width: 10rem;
  height: 3rem;
}
.logo img {
  width: 100%;
  object-fit: cover
}
.hamburger-menu {
  fill: var(--color-white);
  cursor: pointer;
  display: none;
}

</style>
