<template>
  <div class="home">
    <scrollspy></scrollspy>
    <web-view-nav></web-view-nav>
    <banner></banner>
    <about></about>
    <services></services>
    <contact></contact>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import WebViewNav from "@/components/core-ui/nav/web-view-nav.vue";
import Banner from "@/components/core-ui/banner/banner.vue";
import Scrollspy from "@/components/core-ui/scrollspy/scrollspy.vue";
import About from "@/components/core-ui/about/about.vue";
import Services from "@/components/core-ui/services/services.vue";
import Contact from "@/components/core-ui/contact/contact.vue";

export default defineComponent({
  name: 'Home',
  components: {Contact, Services, About, Scrollspy, Banner, WebViewNav},
});
</script>
