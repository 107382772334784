
import { defineComponent, reactive } from "vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default defineComponent({
  name: "slider",
  components: {
    Carousel,
    Slide,
  },
  setup() {
    const slides = reactive([
      {
        imgUrl: "slider-image-2.jpg",
      },
      {
        imgUrl: "slider-image-1.jpg",
      },
      {
        imgUrl: "slider-image-3.jpg",
      },
      {
        imgUrl: "slider-image-4.jpg",
      },
    ])

    return {
      slides
    }
  }

});
